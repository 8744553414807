/* eslint-disable space-before-function-paren */
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'item']

  search() {
    this.itemTargets.forEach(element => {
      if(this.elementMatchesQuery(element)){
        element.classList.remove('isHidden')
      } else {
        element.classList.add('isHidden')
      }
    })
  }

  elementMatchesQuery(element) {
    const query = this.inputTarget.value.toLowerCase().trim()
    return element.dataset.id.toLowerCase().includes(query) || element.dataset.orderId.toLowerCase().includes(query)
  }
}
