import { Controller } from "stimulus";

import { valueCents, formatPrice } from '../utilities/money_input_helpers'

export default class extends Controller {
  static targets = ["amountInput", "soliInput", "priceTotal", "button"];

  connect() {
    this.setTotal()
  }

  change() {
    this.setTotal()
  }

  soliTipChange() {
    this.setTotal()
  }

  setTotal() {
    const productTotal = input =>
      parseInt(input.value) * parseInt(input.getAttribute('data-price')) || 0

    const total = [
      ...this.amountInputTargets.map(productTotal),
      valueCents(this.soliInputTarget.value)
    ].reduce((a, b) => a + b)

    if(total > 0){
      this.buttonTarget.removeAttribute('disabled')
    }else{
      this.buttonTarget.setAttribute('disabled','disabled')
    }

    this.priceTotalTarget.innerHTML = formatPrice(total)
  }
}
