import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileField", "picture"];

  changeImage(e) {
    this.element.classList.add('is-changed')
    const image = e.target.files[0]
    if(image.name.match('.pdf')){
      this.setImage(this.data.get('default'))
    }else{
      const preview = window.URL.createObjectURL(image)
      this.setImage(preview)
    }
  }

  setImage(url) {
    this.pictureTarget.setAttribute('src', url)
  }
}
