import { Controller } from 'stimulus'

import { formatNumber } from '../utilities/money_input_helpers'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.updateValue()
  }

  updateValue() {
    this.inputTarget.value = formatNumber(this.inputTarget.value)
  }
}
