import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["inputField", "formErrors", "productList", "productForm", 'image']

    onCreation(event) {
      let [data, status, xhr] = event.detail;
      this.productListTarget.insertAdjacentHTML('afterbegin', xhr.response)
      this.inputFieldTargets.forEach(element => element.value = '')
      this.imageTarget.setAttribute('src', this.imageTarget.getAttribute('data-image'))
    }

    onCreationFailure(event) {
      let [_data, _status, xhr] = event.detail;
      this.formErrorsTarget.innerHTML = xhr.response
    }
}
