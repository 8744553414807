/* eslint-disable space-before-function-paren */
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  show(e) {
    e.target.remove()
    this.elementTarget.classList.remove('isHidden')
  }
}
