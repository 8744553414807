import { Controller } from 'stimulus'

const LOADING = '⏳'
const SUCCESS = '✔️'
const FAIL = '❌'

export default class extends Controller {
  static targets = ['form', 'result', 'inputField']

  connect() {
    this.setStatus(this.resultTarget.dataset.initial ? SUCCESS : FAIL)
  }

  submit() {
    this.setStatus(LOADING)
    if(this.formTarget.requestSubmit){
      this.formTarget.requestSubmit()
    }else{
      this.formTarget.submit()
    }

  }

  success() {
    this.setStatus(SUCCESS)
  }

  error(event) {
    const error_message = event.detail && event.detail[0] && event.detail[0].join('. ')

    this.setStatus(`${FAIL} ${error_message}`)
  }

  setStatus(status) {
    this.resultTarget.textContent = status
  }
}
