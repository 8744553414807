/* eslint-disable space-before-function-paren */
import { Controller } from 'stimulus'

export default class extends Controller {
  change(e) {
    if(parseInt(e.target.value) > 0 && /^\d+$/.test(e.target.value)){
      this.element.classList.add('isChecked')
    }else{
      this.element.classList.remove('isChecked')
    }
  }
}
