/* eslint-disable space-before-function-paren */
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['password', 'confirmation']

    change() {
        this.passwordTarget.setAttribute('type', 'text')
        this.confirmationTarget.setAttribute('type', 'text')
        const valid = this.inputsValid(
            this.passwordTarget.value,
            this.confirmationTarget.value
        )
        this.passwordTarget.setAttribute('type', 'password')
        this.confirmationTarget.setAttribute('type', 'password')
        if (valid) {
            this.passwordTarget.classList.add('Input--valid')
            this.confirmationTarget.classList.add('Input--valid')
        } else {
            this.passwordTarget.classList.remove('Input--valid')
            this.confirmationTarget.classList.remove('Input--valid')
        }
    }

    inputsValid(password, confirmation) {
        return (
            password.length > 5 &&
            confirmation.length > 5 &&
            password === confirmation
        )
    }

    submit(event) {
        if (this.passwordTarget.value != this.confirmationTarget.value) {
            this.passwordTarget.value = ''
            this.confirmationTarget.value = ''
            this.passwordTarget.focus()
            this.passwordTarget.setCustomValidity(
                'Die eingegebenen Passwörter müssen übereinstimmen.'
            )
        } else this.passwordTarget.setCustomValidity('')
    }
}
