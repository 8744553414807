const numberFormatter = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false
})

const priceFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
})

export const valueCents = (value) => {
  const valueString = value.replace(/,/g, '.')

  return (parseFloat(valueString) * 100) || 0
}

export const formatNumber = (value) => {
  if(typeof(value) === 'string') {
    value = valueCents(value)
  }

  return numberFormatter.format(value / 100.0)
}

export const formatPrice = (value) => {
  if(typeof(value) === 'string') {
    value = valueCents(value)
  }

  return priceFormatter.format(value / 100.0)
}
