/* eslint-disable space-before-function-paren */
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['categorySelector', 'company', 'companySearchfield', 'noResults', 'subregionSection', 'subregionSelector']

  connect() {
    this.filterCompanies()
  }

  filterCompanies() {
    const categoryFilter = this.categorySelectorTarget.value
    const companyNameQuery = this.companySearchfieldTarget.value.toLowerCase().trim()

    this.companyTargets.forEach(company => {
      const nameMatchesQuery = !companyNameQuery || company.dataset.name.toLowerCase().includes(companyNameQuery)
      const categoryMatchesSelection = !categoryFilter || company.dataset.categoryId === categoryFilter

      this.toggleElementVisibility(company, nameMatchesQuery && categoryMatchesSelection)
    })

    this.updateSubregionCount()
    this.filterSubregions()
  }

  updateSubregionCount() {
    this.subregionSectionTargets.forEach(subregion => {
      let visibleCompanies = subregion.querySelectorAll('.Company-card').length - subregion.querySelectorAll('.Company-card.uHidden').length
      subregion.querySelector('.Company-subregionCount').innerHTML = visibleCompanies
    })
  }

  filterSubregions() {
    const subregionFilter = this.subregionSelectorTarget.value

    this.subregionSectionTargets.forEach(subregion => {
      let regionMatches = !subregionFilter || subregion.dataset.id === subregionFilter
      let regionHasCompaniesVisible = parseInt(subregion.querySelector('.Company-subregionCount').innerHTML) > 0

      this.toggleElementVisibility(subregion, regionMatches && regionHasCompaniesVisible)
    })

    const showNoResult = this.subregionSectionTargets.every(subregion => subregion.classList.contains('uHidden'))
    this.toggleElementVisibility(this.noResultsTarget, showNoResult)
  }

  toggleElementVisibility(element, visible = false) {
    if(visible) {
      element.classList.remove('uHidden')
    } else {
      element.classList.add('uHidden')
    }
  }
}
