// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'

require("chartkick")
require("chart.js")

import '../images'
import '../documents/plakat_lokale.pdf'
import '../documents/gutschein_entwerten.pdf'
import '../documents/future.pdf'
import '../src/stylesheets/foundation.sass'
import '../src/stylesheets/application.sass'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('../src/javascript/controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

Rails.start()
Turbolinks.start()
